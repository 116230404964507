import React, { Component } from 'react';
import axios from 'axios';
import './Item.css';
import ListItem from './Listitem'


class Item extends Component {
    state = {
        items:[],
        itemid:""
    }
    componentDidMount(){
        let id = this.props.match.params.item_id;
        
        axios.get("/data/items.json")
            .then(res => {
                this.setState({
                    items: res.data.slice(),
                    itemid:id
                })
            })
    }

    render(){
        
        let itemid = this.props.match.params.item_id;

        const {items} = this.state;
        var i = 0;
        const itemlist = items.length ? (
            items.filter(item => item.itemid === itemid).map(item => {
                i++;
                const includelist = item.includes.length ? (                                         
                                                            <div ><p className="card-text">This kit includes:</p>
                                                            <ListItem item_list={item.includes}/>
                                                            </div>):(<div></div>);
                const needslist = item.needs.length ? (                            
                                    <div><p className="card-text">You will need:</p>
                                    <ListItem item_list={item.needs}/>
                                    </div>):(<div></div>);
                const buildguide = item.buildguide ? (<span>You can find a build guide <a href={item.buildguide} target="_blank" rel="noopener noreferrer">here</a></span> ):(<span></span>);
                return(
                    <div key = {i} className="card col-sm-12 col-md-10 col-lg-8 col-xl-6 p-1 m-1">
                    
                        <img src={item.image} className="card-img-top" alt="..."/>
                        <div className="card-header"><h5 className="card-title">{item.title}</h5></div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-8">
                                <p className="card-text">{item.introduction}</p>
                                </div>
                                <div className="col-4">
                                <div className="d-flex flex-row-reverse">
                                <div className="btn-group btn-group-lg justify-content-end" role="group" >

  <button type="button" className="btn btn-secondary btn-success">{item.price}US$</button>
  <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                                        <input type="hidden" name="cmd" value="_s-xclick"/>
                                        <input type="hidden" name="hosted_button_id" value={item.paypalbuttonid}/>
                                        <button  className="btn btn-primary btn-lg">Add to Cart</button>
                                    </form>
</div>

                                </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                
                                    {includelist}


                                </div>
                                <div className="col-4 ">
                                <div className="d-flex flex-row-reverse">

<p className="card-text">In Stock: {item.quantities}</p>
</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                

                                    {needslist}

                                </div>
                                <div className="col-4">


                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                

                                    <p className="card-text">{item.conclusion}</p>
                                    <p className="card-text">{buildguide}</p>
                                    <p className="card-text">{item.disclaimer}</p>
                                </div>
                                <div className="col-4">

                                </div>
                            </div>
                                

                                    

                                    
                             </div>
   
                             

                      
                            

                        
                    </div>
            );
             })
        ):(
            <div className="card" >
            These aren't the droids you are looking for...
            </div>
        )


        return(
            <main role="main" >
              <div className="d-flex flex-wrap flex-row bd-highlight mb-3 ml-sm-auto ml-md-auto  col-sm-12 col-md-10 col-lg-10  col-xl-14 px-2 mx-2">
                  {itemlist}
              </div> 
            </main>
                
 
        );
    }

}

export default Item;

