import React, { Component } from 'react';
import {  NavLink } from "react-router-dom";

class NavCard extends Component {

    render(){
        const { path, name, description, image } = this.props;
        return(
                
                
                <div className="card w-25">
                  <img src={image} className="card-img-top" alt=""/>
                  <div className="card-body">
                    <h5 className="card-title"><NavLink exact to={ path }  > { name } </NavLink></h5>
                    
                    <p className="card-text">{description}</p>
                    
                  </div>
                </div>
              
        );
    }

}

export default NavCard;