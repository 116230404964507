import React, { Component } from 'react';

class ListItem extends Component {

    render(){
       // console.log(this.props.item_list);
        let itemlist = this.props.item_list;
        var i = 0;
        const list = itemlist.map(item => {
                i++;
                return(
                    <li key={i} >{item}</li>
                );
        });

        return(
            <ul> {list}</ul>
                
 
        );
    }
}

export default ListItem;