import React, { Component } from 'react';
import axios from 'axios';
import {  NavLink } from "react-router-dom";
import './Group.css';


class Group extends Component {
    state = {
        items:[],
         groupid:""
    }
    componentDidMount(){
        let id = this.props.match.params.group_id;
        axios.get("/data/items.json")
            .then(res => {
                this.setState({
                    items: res.data.slice(),
                    groupid:id
                })
            })
    }

    render(){
        let groupid = this.props.match.params.group_id;
        let message =  <div className="alert alert-primary" role="alert"> COVID-19 Updates: We are still shipping out to Canada and USA (From Canada) but have updated shipping prices to include tracking. International Shipping is still untracked and on a per-case basis and depends on CanadaPost service availability. </div>;
        let message2 = <div className="alert alert-danger" role="alert"> Group Buy Items are not regularly in stock and several orders will be bundled and a large order will be made for production. Shipment to you will likely be delayed by several weeks. </div>;
        let message3 = <div className="alert alert-danger" role="alert"> Lithium batteries are <a href="https://tc.canada.ca/en/dangerous-goods/transportation-dangerous-goods/shipping-importing-devices-containing-lithium-batteries">regulated dangerous goods.</a> Their shipment is limited to North America. I may need to contact you in case I can't ship them. </div>;
      
        const {items} = this.state;

        const itemlist = items.length ? (
            items.filter(item => item.groupid === groupid).map(item => {
                    return(  
                        <div className="card  col-sm-5 col-md-5 col-lg-5 col-xl-3 p-1 m-1" key={item.itemid} >
                            <div>
                                <img src={item.image} className="card-img-top" alt=""/>
                                <div className="card-body">
                                    <h5 className="card-title"> {item.title}</h5> 
                                    
                                    <div className="btn-group btn-group justify-content-end" role="group" >
                                    <button type="button" className="btn btn-secondary btn-warning"><NavLink className = "text-dark" exact to={"/item/"+item.itemid }   ><i className="fas fa-search-plus"></i></NavLink></button>
                                        <button type="button" className="btn btn-secondary btn-success">{item.price}US$</button>
                                    <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                                        <input type="hidden" name="cmd" value="_s-xclick"/>
                                        <input type="hidden" name="hosted_button_id" value={item.paypalbuttonid}/>
                                        <button  className="btn btn-primary">Add to Cart</button>
                                    </form>
                                    </div>
                                    <p className="card-text">{item.description}</p>
                                    <p className="card-text">{item.description1} <NavLink exact to={"/item/"+item.itemid }   >See More Details... </NavLink> </p>
                                </div>
                            </div>
                        </div> 
                    )
                })
        ):(
            <div className="card" >
            These aren't the droids you are looking for...
            </div>
        )

        return(
            <main role="main" >
                
              <div className="d-flex flex-wrap flex-row bd-highlight mb-3 ml-sm-auto ml-md-auto  col-sm-12 col-md-10 col-lg-10  col-xl-14 px-2 mx-2">
              {(groupid ==="battery") ? message3 : ''} 
                {(groupid ==="groupbuy") ? message2 : ''} 
                {message}
                </div>
                <div className="d-flex flex-wrap flex-row bd-highlight mb-3 ml-sm-auto ml-md-auto  col-sm-12 col-md-10 col-lg-10  col-xl-14 px-2 mx-2"> 
                  {itemlist}
              </div> 
            </main>
                
 
        );
    }

    

}

export default Group;