import React, { Component } from 'react';
import {  NavLink } from "react-router-dom";

class Nav extends Component {

    render(){
        const { path, name, icon } = this.props;
        return(

            <li className="nav-item">
                <NavLink exact to={ path }  className="nav-link" activeClassName="active" > <i className={ icon } ></i>{ name } </NavLink>
            </li>
        );
    }

}

export default Nav;