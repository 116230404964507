import React, { Component } from 'react';
import { HashRouter as Router, Route, NavLink} from "react-router-dom";
import './App.css';
import Nav from './Nav'
import NavCard from './NavCard'
import Group from './Group'
import Item from './Item'


class App extends Component {
  render() {
    return (
<Router>
    <div className="container-fluid">
      <div className="row">
        <nav className="col-md-2 d-none d-md-block bg-light sidebar">
          <div className="sidebar-sticky">
            <ul className="nav flex-column">
            <Nav name="Home" path= "/" icon= "fab fa-canadian-maple-leaf"/>
            <Nav name="Wireless Accessories" path= "/group/bluemicro" icon= "fab fa-bluetooth-b"/>
            <Nav name="Batteries" path= "/group/battery" icon= "fas fa-car-battery"/>
            <Nav name="Programming Accessories" path= "/group/program" icon= "fas fa-bug"/>
            <Nav name="Wired Accessories" path= "/group/wired" icon= "fab fa-usb"/>
            <Nav name="Keyboards" path= "/group/keyboards" icon= "far fa-keyboard"/>
            <Nav name="Macropads" path= "/group/macropads" icon= "fas fa-th"/>
            <Nav name="Split Keyboards" path= "/group/split_boards" icon= "far fa-clone"/>
            <Nav name="Group Buys" path= "/group/groupbuy" icon= "fas fa-users"/>
            </ul>
          </div>
          
          <footer className="footer">  
          <NavLink exact to="/about" className="nav-link"  > <span className="text-muted">&copy; JPConstantineau.com 2019-2020</span></NavLink>
               
                 
          </footer>
        </nav>

      </div> 
                <Route exact path="/" component={Home} />
                <Route path="/group/:group_id" component={Group} />
                <Route path="/item/:item_id" component={Item} />
                <Route path="/about" component={About} />
                <Route path="/soldout" component={Soldout} />
                <Route path="/success" component={Success} />
                <Route path="/cancel" component={Cancel} />
                <Route path="/subscribe" component={Subscribe} />
      </div>
      </Router>
    );
  }
}



function Home() {
  return (
    <div>
        <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-2">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-2 pb-1 mb-2 ">
            <div id="page-content-wrapper" name="page-content-wrapper">
            <div className="alert alert-primary" role="alert">
 COVID-19 Updates: We are still shipping out to Canada and USA (From Canada) but have updated shipping prices to include tracking. International Shipping is still untracked and on a per-case basis and depends on <a href="https://www.canadapost.ca/assets/pdf/aboutus/servicealerts/destinations-to-which-service-is-suspended.pdf">CanadaPost's service availability.</a>
</div>
                <div className="jumbotron">
                    <h1 className="display-4">DIY Wireless Keyboards Made Simple</h1>
                    <p className="lead">Making Bluetooth available to DIY Keyboards since 2018! </p>
                    <hr className="my-4"/>
                    <span className="text-muted">Subscribe to our mailing list <a href= "/#/subscribe">here </a></span>  

                    <h2>Browse by category</h2>
                </div>
                <div className="container-fluid">
                  
                  <div className="row">
                    <NavCard name="BlueMicro: nRF52832 and nRF52840 boards" path= "/group/bluemicro" description= "Making Bluetooth available to DIY keyboard builders" image="/img/Wireless.PNG"/>
                    <NavCard name="ErgoTravel" path= "/group/split_boards" description= "Travel-sized Split Keyboards. Designed for the BlueMicro" image="/img/ergotravellight_v1.01.jpg"/>
                    <NavCard name="Macro Pads" path= "/group/macropads" description= "Bluetooth macropads: Built from the ground up." image="/img/4x4mx.jpg"/>
                    <NavCard name="Keyboards" path= "/group/keyboards" description= "Small 30% to 60% keyboards. Compatible with the BlueMicro" image="/img/4x12contra.jpg"/>
                  </div>
                </div>
              </div>
            </div>
        </main>

    </div>
  );
}

function Soldout() {
  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-2">
      <h2>Soldout</h2>
      <p >The Item you have selected is sold out.</p>
    </main>
  );
}


function Subscribe() {
  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-2">
      
      <p><a href="https://us12.campaign-archive.com/home/?u=53180efc53c5ec0fd6f349bed&id=f52b1660a9" title="View previous campaigns">View previous campaigns.</a></p>

    </main>
  );
}

function Cancel() {
  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-2">
      <h2>Checkout Cancelled</h2>
      <p >You have cancelled your checkout.</p>
      <span className="text-muted">Subscribe to our mailing list <a href= "/#/subscribe">here </a></span> 
    </main>
  );
}

function Success() {
  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-2">
      <h2>Your order was placed successfully</h2>
      <p>Everything will be packed and shipped by CanadaPost.</p>
      <p >You will be contacted by email (address on file with Paypal) with a question for the following:</p>
      <ul>
        <li>Confirmation of your shipping address</li>
        <li>Contact Phone Number for the Shipping Label</li>
      </ul>
      <p>In case I have any questions or clarifications regarding the order, I will email you.</p>
    </main>
  );
}

function About() {
  return (
    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-2">
      <h2>About</h2>
      <span className="text-muted">Subscribe to our mailing list <a href= "/#/subscribe">here </a></span> 
      <p ><img src="/img/under-construction.gif" width="25" height="25" class="d-inline-block align-mid"   alt=""/>Just like in the 90's, this web page is still under construction! <img src="/img/under-construction.gif" width="25" height="25" class="d-inline-block align-mid"   alt=""/></p>
    </main>
  );


}

export default App;
